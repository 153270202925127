
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import CreditCard from '@/components/pages/shared/payment/CreditCard.vue'
import PaymentMethods from '@/components/pages/shared/payment/PaymentMethods.vue'
import MobilePaymentMethods from '@/components/pages/shared/payment/MobilePaymentMethods.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    CreditCard,
    MobilePaymentMethods,
    PaymentMethods,
    TmButton,
    TmModal,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const currentTab = ref()

    return {
      isSmMax,
      currentTab,
    }
  },
})
