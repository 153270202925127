
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    text: {
      type: String,
    },
    logos: {
      type: Array as PropType<string[]>,
    },
  },
})
