import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64d91330"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-payment-method" }
const _hoisted_2 = { class: "mb-2 headline-18 lh-22" }
const _hoisted_3 = { class: "body-text-regular-14 lh-20 pr-8 mb-2" }
const _hoisted_4 = { class: "mobile-payment-method__logos" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "mobile-payment-method__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logos, (logo, i) => {
        return (_openBlock(), _createElementBlock("img", {
          key: i,
          src: logo
        }, null, 8, _hoisted_5))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_tm_icon, {
        name: "chevron_right",
        class: "primary--text",
        size: "xxxLarge"
      })
    ])
  ]))
}