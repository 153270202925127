import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41d1676d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-2 headline-18 lh-22" }
const _hoisted_2 = { class: "mobile-payment-methods__logos" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_payment_method = _resolveComponent("mobile-payment-method")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.currentTab)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paymentMethods, (item, i) => {
          return (_openBlock(), _createBlock(_component_mobile_payment_method, _mergeProps({ key: i }, item, {
            onClick: ($event: any) => (_ctx.$emit('update:currentTab', item))
          }), null, 16, ["onClick"]))
        }), 128))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.currentTab.label), 1),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentTab.logos, (logo, i) => {
              return (_openBlock(), _createElementBlock("img", {
                key: i,
                src: logo
              }, null, 8, _hoisted_3))
            }), 128))
          ]),
          _renderSlot(_ctx.$slots, `tab-${_ctx.currentTab.name}`, {}, undefined, true)
        ], 64))
  ]))
}