
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import MobilePaymentMethod from '@/components/pages/shared/payment/mobilePayment/MobilePaymentMethod.vue'
import { mobileCreditCard, mobilePaypal } from '@/definitions/shared/payment/data'
import type { RadioTab } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    MobilePaymentMethod,
  },
  props: {
    currentTab: {
      type: Object as PropType<RadioTab>,
    },
  },
  emits: ['update:currentTab'],
  setup() {
    const paymentMethods: RadioTab[] = [mobileCreditCard, mobilePaypal]

    return {
      paymentMethods,
    }
  },
})
